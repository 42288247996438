import React from "react"
const Video = ({ videoSrcURL, videoTitle,width, height,...props }) => (
  <figure className="image is-16by9">
      <iframe
        className={"has-ratio"}
        src={videoSrcURL}
        title={videoTitle}
        width={width}
        height={height}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
  </figure>
)
export default Video