import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {graphql, Script, withPrefix} from 'gatsby'

import Layout from '../components/Layout'
import Video from "../components/Video";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCoffee,
  faCube,
  faVideo,
  faCloudMoon,
  faCameraRetro,
  faHelicopter,
  faTram,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons'
library.add(faCoffee, faCube, faVideo,
    faCloudMoon,
    faCameraRetro,faHelicopter, faTram,faChevronDown, faChevronLeft, faChevronRight);
export const RenderServiceSection = (service, mobileFlag = false) => {
  const [activeImageIndex, setActiveImageIndex] = useState(-1);
  const [imagesArray, setImagesArray] = useState(null);

  return (
      <section
          className={`section fade-in-image ${service.orderNumber % 2 !== 0 || !mobileFlag ? "has-background-white-ter" :"has-background-grey-lighter"}`}
          style={{padding: "2rem 1.5rem",
            minHeight: !mobileFlag ? "68vh" : "0vh"}}
          key={service.orderNumber}
      >
        <div className={"container my-5"}>
          {GalleryImagesModal(imagesArray, activeImageIndex, setActiveImageIndex, mobileFlag)}
          <div className={"is-centered has-text-centered mb-5 mt-5 pb-3"}>
            <h1
                className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-2-widescreen"
            >
              {service.title}
            </h1>
          </div>
          { service.items && service.items.map((item, itemIndex) => (
              <div
                  key={itemIndex.toString()}
                  style={{
                    lineHeight: '1',
                    justifyContent: 'space-around',
                  }}
                  className={"columns is-multiline"}
              >
                <div
                    style={{
                      lineHeight: '1',
                      justifyContent: 'space-around',
                      padding: 0
                    }}
                    className={"columns my-3 level"}
                >
                  <div className={`column is-one-fifth level-item ${mobileFlag ? "" : "mr-6"}`}>
                    <div className={"content box is-center"}  style={{border: `3px solid gold`}}>
                      <p className={"is-text is-4"}>{item.description}</p>
                    </div>
                  </div>
                  {service.mode === "images" && (
                      <div className={"column is-four-fifths level-item"}>
                        <div className={"columns is-variable is-1 is-multiline"}>
                          {item.images && item.images.map((image, imageIndex) => (
                              <div
                                  key={itemIndex.toString() + imageIndex.toString()}
                                  className={"column is-one-third"}>
                                <a onClick={() => {
                                  setActiveImageIndex(imageIndex);
                                  setImagesArray(item.images);
                                }}>
                                  <img className={"is-4by3"} src={`${
                                      !!image.image.childImageSharp ? image.image.childImageSharp.fluid.src : image.image
                                  }`} alt="" style={{
                                    width:"100%",
                                    height:"268px",
                                    objectFit: "fill"
                                  }}/>
                                </a>
                              </div>
                          ))}
                        </div>
                      </div>
                  )}

                  {service.mode === "videos" && (
                      <div className={"column is-four-fifths"}>
                        <div className={"columns is-variable is-1"}>
                          {service.mode === "videos" && item.videoUrl && (
                              <div
                                  className={"column level-item"}>
                                <Video
                                    videoSrcURL={item.videoUrl}
                                    videoTitle={"temp"}
                                    width={"640"}
                                    height={"360"}
                                />
                              </div>
                          )}
                        </div>
                      </div>
                  )}

                  {service.mode === "matterport" && (
                      <div className={"column is-four-fifths"}>
                        <div className={"columns is-variable is-1"}>
                          {item.matterPortUrl && (
                              <div
                                  className={"column level-item"}>
                                <figure className="image is-16by9">
                                  <iframe className={"has-ratio"} width="640" height="360" frameBorder="0" title={service.title}
                                          src={item.matterPortUrl} allowFullScreen/>
                                </figure>
                              </div>
                          )}
                        </div>
                      </div>
                  )}
                </div>
              </div>
          ))}

        </div>
      </section>
  )
}

export const GalleryImagesModal = (images, activeImageIndex, setActiveImageIndex, mobileFlag) => {
  return (
      <div className={`modal ${images && activeImageIndex >= 0 && !mobileFlag ? "is-active" : "" }`}>
        <div className="modal-background" onClick={() => {
          setActiveImageIndex(-1)
        }}></div>
        {images && activeImageIndex >= 0 && (
            <div className="modal-content" style={{height:"auto", width: "75vw"}}>
              <div className={"is-centered has-text-centered fade-in-image"} style={{height: "100%"}} onClick={() => {
                setActiveImageIndex(-1)
              }}>
                <img className={"fade-in-image"} src={!!images[activeImageIndex].imageHQ.childImageSharp ? images[activeImageIndex].imageHQ.childImageSharp.fluid.src : images[activeImageIndex].imageHQ} alt="" style={{height: "100%"}}/>
              </div>
              {/*<div className={"mt-5"} style={{height: "20%", width: "95%"}}>*/}
              {/*  <div className={"columns"}>*/}
              {/*    {galleryImages.map((galleryImage, galleryIndex) => (*/}
              {/*      <div className={"column"} key={galleryIndex.toString() + "galleryImageItem"}>*/}
              {/*        <img src={!!galleryImage.image.childImageSharp ? galleryImage.image.childImageSharp.fluid.src : galleryImage.image} alt="" style={{height: "100%"}} onClick={() => {*/}
              {/*          setActiveImageIndex(galleryIndex)*/}
              {/*        }}/>*/}
              {/*      </div>*/}
              {/*    ))}*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
        )}
        <button className="modal-close is-large" aria-label="close" onClick={() => {
          setActiveImageIndex(-1)
        }}/>
      </div>
  )
}

const BookSection = (backgroundClassName) => {
  return <div className={`${backgroundClassName} py-5`}>
    <div className={"container px-5"}>
      <div className={"is-centered has-text-centered "}>
        <Script id="setmore_script" type="text/javascript" src={`https://storage.googleapis.com/fullintegration-live/webComponentAppListing/Container/setmoreIframeLive.js`}></Script>
        <a className="is-centered has-text-centered button is-primary is-large is-size-4"  id="Setmore_button_iframe"  href="https://booking.setmore.com/scheduleappointment/c839510d-aa35-4808-8112-d47a5dac7e6c">
          Book Now
        </a>
      </div>
    </div>
  </div>
}


export const IndexPageTemplate = (
    {
      image,
      title,
      headingpart1,
      headingpart2,
      mainpitch,
      intro,
      services,
    }) => {
  const [activeTab, setActiveTab] = useState(services[0]);

  const packages = [{
    title: "Photo Tour",
    services: [
      "Individually edited photos",
      "24hr turnaround",
    ],
    cost: 249
  },
    {
      title: "Photo & Video Tour",
      services: [
        "Individually edited photos",
        "Cinematic videos",
        "Cinematic branded & MLS videos",
        "MLS video link",
        "24hr turnaround",
      ],
      cost: 499
    },
    {
      title: "Photo & 3D Matterport Tour",
      services: [
        "Individually edited photos",
        "3D Matterport Tour",
        "24hr turnaround",
      ],
      cost: 499
    },
    {
      title: "Photo & Video + Aerial Photo & Video",
      services: [
        "Individually edited photos",
        "Cinematic videos",
        "Aerial drone photos & videos",
        "Cinematic branded & MLS videos",
        "MLS video Link",
        "24hr turnaround",
      ],
      cost: 499
    },
    {
      title: "Virtual Tour Package",
      services: [
        "Individually edited photos",
        "3D Matterport Tour",
        "Cinematic branded & MLS videos",
        "MLS video link",
        "Floor plans & measurements",
        "Property website",
        "24hr turnaround",
      ],
      cost: 799
    },
    {
      title: "Full Tour Package",
      services: [
        "Individually edited photos",
        "3D Matterport Tour",
        "Cinematic branded & MLS videos",
        "MLS video link",
        "Floor plans & measurements",
        "Property website",
        "Aerial Drone Photos & Video",
        "24hr turnaround",
      ],
      cost: 999
    },
  ];

  let servicesBegin;
  return (
      <div>
        <section className={"hero"}>
          <figure className={"hero-body"} style={{height: "100%", padding: 0}}>
            <video loop="loop" muted autoPlay="autoplay" style={{zIndex: -1, objectFit: "cover", height: "100vh", width: "100vw"}}>
              <source src="/video/Intro_Landing.mp4"/>
            </video>
          </figure>
          <div style={{height: "100%", width: "100%", position: "absolute"}} className={"is-flex is-align-items-end is-justify-content-center"}>
            <a className={"is-flex is-align-content-end"} title={"Scroll Down"} role={"link"} href={null} onClick={() => {
              servicesBegin.scrollIntoView({ behavior: "smooth" });
            }}>
              <FontAwesomeIcon icon={['fa', "chevron-down"]} size="5x" color={"gold"} />
            </a>
          </div>
        </section>

        <div id={"services-section"} ref={(el) => { servicesBegin = el; }}>
          <div className={"is-hidden-touch"}>
            <div className={"tabs is-fullwidth"}>
              <ul>
                {services && services.map((service, liIndex) => (
                    <li key={service.title + "li" + liIndex.toString()} className={`${service === activeTab ? "is-active" : ""}`}>
                      <a title={service.title} role={"link"} href={null} onClick={() => {
                        setActiveTab(service);
                      }}>
                        {service.fontIconName && (
                            <FontAwesomeIcon icon={['fa', service.fontIconName]} size="1x" />
                            // <span className="icon is-small"><i className={`fas ${service.fontIconName}`}></i></span>
                        )}&nbsp;
                        <span>{service.title}</span>
                      </a>
                    </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={"is-hidden-touch"}>
            {RenderServiceSection(activeTab, false)}
          </div>

          <div className={"is-hidden-desktop"}>
            {services && services.map((service) => (
                RenderServiceSection(service, true)
            ))}
          </div>
        </div>

        {/*{BookSection(services.length % 2 === 0 ? "has-background-white-ter" : "has-background-grey-lighter")}*/}
        {/*PACKAGES SECTION*/}
        <div className={`${services.length % 2 === 0 ? "has-background-white-ter" : "has-background-grey-lighter"} py-5`}>
          <div className={"container pb-5 px-5"}>
            <div className={"is-centered has-text-centered mb-4"}>
              <h1 className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen is-centered has-text-centered">
                Package Prices
              </h1>
            </div>
            <div className={"mb-5"}>
              {BookSection(services.length % 2 === 0 ? "has-background-white-ter" : "has-background-grey-lighter")}
            </div>
            <div className={"columns is-variable is-5 is-centered is-multiline"}>
              {packages && packages.map((packageOption, packageIndex) => (
                  <div key={packageIndex.toString() + packageOption.title} className={"column is-half"}>
                    <div className={"box"} style={{height: "100%", border: `3px solid gold`}}>
                      <div className={"content columns level is-centered mt-0 mb-0 is-vcentered"}  style={{height: "100%"}}>
                        <div className={"column is-half"}>
                          <h1>{packageOption.title}</h1>
                          <ul>
                            {packageOption.services.map(service => (
                                <li key={packageIndex.toString() + packageOption.title + service}>{service}</li>
                            ))}
                          </ul>
                        </div>
                        <div className={"column is-centered is-half is-flex is-justify-content-start is-align-items-center is-vcentered"} style={{
                          borderLeft: "1px gray",
                          borderLeftStyle: "solid",
                          height: "100%"
                        }}>
                          <div className={"columns is-centered"} style={{width: "100%"}}>
                            <div className={"column is-narrow"}>
                              <h3 className={"mb-0 mt-5 text is-size-6"}>From</h3>
                              <h1 className={"mt-0"}>${packageOption.cost}</h1>
                              {/*<Script id="setmore_script" type="text/javascript" src={`https://storage.googleapis.com/fullintegration-live/webComponentAppListing/Container/setmoreIframeLive.js`}></Script>*/}
                              {/*<a className="is-centered has-text-centered"  id="Setmore_button_iframe"  href="https://booking.setmore.com/scheduleappointment/c839510d-aa35-4808-8112-d47a5dac7e6c"><img border="none" src="https://storage.googleapis.com/full-assets/setmore/images/1.0/Settings/book-now-blue.svg" alt="Book an appointment with Richard Chau using Setmore"/></a>*/}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              ))}
            </div>
            <div className={"columns is-centered"}>
              <div className={"column is-half"}>
                <div className={"box"} style={{border: `3px solid gold`}}>
                  <h1 className={"title is-3 is-centered has-text-centered"}>Individual and Add on</h1>
                  <div className={"content"}>
                    <p className={"text is-size-6"}>4k Cinematic Video $349</p>
                    <p className={"text is-size-6"}>Aerial Photos and 4K Video $349</p>
                    <p className={"text is-size-6"}>Twilight Photos $149 / Twilight Photos & Videos $249</p>
                    <p className={"text is-size-6"}>Agent Intro/Voiceover $99</p>
                    <p className={"text is-size-6"}>Social Media 4k Vertical Instagram Reel, Tiktok, Facebook $149</p>
                  </div>
                  <div className={"content"}>
                    <h1 className={"title is-5"}>3D Matterport Tour & Floor Plan Options Starting at $299</h1>
                    <ul>
                      <li className={"text is-size-6"}>Under 2500 sqft $299</li>
                      <li className={"text is-size-6"}>2500 - 5000 sqft $399</li>
                      <li className={"text is-size-6"}>5000+ sqft $499</li>
                      <li className={"text is-size-6"}>Matterport Floor Plans $75</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  Book Section */}
        {/*{BookSection(services.length % 2 === 0 ? "has-background-white-ter" : "has-background-grey-lighter")}*/}

        {/*<div className={`${services.length % 2 === 0 ? "has-background-grey-lighter" : "has-background-white-ter"} py-5`}>*/}
        {/*  <div className={"container px-5"}>*/}
        {/*    <div className={"is-centered has-text-centered "}>*/}
        {/*      <Script id="setmore_script" type="text/javascript" src={`https://storage.googleapis.com/fullintegration-live/webComponentAppListing/Container/setmoreIframeLive.js`}></Script>*/}
        {/*      <a className="is-centered has-text-centered button is-primary is-large"  id="Setmore_button_iframe"  href="https://booking.setmore.com/scheduleappointment/c839510d-aa35-4808-8112-d47a5dac7e6c">*/}
        {/*        <FontAwesomeIcon icon={['fa', "chevron-right"]} size="1x" color={"gold"} />*/}
        {/*        Book Now*/}
        {/*        <FontAwesomeIcon icon={['fa', "chevron-left"]} size="1x" color={"gold"} />*/}
        {/*      </a>*/}

        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
  )
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  headingpart1: PropTypes.string,
  headingpart2: PropTypes.string,
  mainpitch: PropTypes.object,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  services: PropTypes.array,
}

const IndexPage = ({data}) => {
  const {frontmatter} = data.markdownRemark

  return (
      <Layout>
        <IndexPageTemplate
            image={frontmatter.image}
            title={frontmatter.title}
            headingpart1={frontmatter.headingpart1}
            headingpart2={frontmatter.headingpart2}
            mainpitch={frontmatter.mainpitch}
            intro={frontmatter.intro}
            services={frontmatter.services}
        />
      </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        headingpart1
        headingpart2
        mainpitch {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
        }
        intro {
          blurbs {
            title
            text
          }
        }
        services {
          title
          mode
          fontIconName
          orderNumber
          items {
            description
            videoUrl
            matterPortUrl
            images {
              image {
                childImageSharp {
                  fluid(maxWidth: 512, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              imageHQ {
                childImageSharp {
                  fluid(maxWidth: 1024, quality: 50) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
